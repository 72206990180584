<template>
  <div class="alarmRuleAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false"
               @close="closeDio('dioform1')">
      <div class="contDio">
        <el-form ref="dioform1" :model="inform" size="small" :inline="true">
          <template v-for="item in addressConfigArr">
            <el-form-item label="" prop="deviceName">
              <el-input v-model="item.deviceName" disabled></el-input>
            </el-form-item>
            <el-form-item label="" prop="address">
              <el-input-number v-model="item.num" @change="handleChange" :min="1" :max="255"
                               step-strictly>
              </el-input-number>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform1')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform1')" size="small">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
var vm;
export default {
  data() {
    return {
      isDio: false,//弹窗类型
      dioTit: '',//弹窗标题
      dioType: '',//弹窗类型
      dioWidth: '',//弹窗宽度
      //form信息
      addressConfigArr:[],
      inform: {
        id: '',
        addressConfigArr: []
      },

      rules: {},
    }
  },
  created() {
    vm = this
  },
  mounted() {

  },
  //方法
  methods: {
    //初始化
    async init(item, addressConfigArr) {
      vm.addressConfigArr = [];
      for(let i=0;i<addressConfigArr.length;i++){
        vm.addressConfigArr.push({
          deviceName: addressConfigArr[i].deviceName,
          num: addressConfigArr[i].address,
          id:addressConfigArr[i].id
        });
      }
      vm.dioWidth = '440px';
      vm.dioTit = '从机地址配置';
      vm.$nextTick(() => {
        vm.inform = {
          id: item.id,
          addressConfigArr: []
        }
        console.log(vm.inform);
      });
      vm.isDio = true
    },
    handleChange(e, item) {
      console.log(e);
    },
    //校验form
    checkForm(formEl) {
      for (let i = 0; i < vm.addressConfigArr.length; i++) {
        vm.inform.addressConfigArr.push({
          deviceName: vm.addressConfigArr[i].deviceName,
          address: vm.addressConfigArr[i].num,
          id:vm.addressConfigArr[i].id
        });
      }
      this.$emit('update', vm.inform);
      vm.closeDio(formEl);
    },
    //关闭弹窗
    closeDio(formEl) {
      vm.inform = {}
      vm.addressConfigArr = [];
      vm.isDio = false
    },
  }
}
</script>
<style lang='scss'>
.alarmRuleAddEdit {
  .contDio {
    max-height: 500px;
    overflow: auto;
  }

  .el-form-item {
    margin: 3px;
  }
}
</style>