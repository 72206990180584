<template>
  <div class="deviceSensorAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false"
               @close="closeDio('dioform')">
      <div class="contDio h500">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="100px" size="small">
          <el-row>
            <el-col :span="12">
              <el-form-item label="场所:" prop="homeId">
                <el-select v-model="inform.homeId" placeholder="请选择场所">
                  <el-option v-for="(item,index) in dicObj.homeList" :key="index" :label="item.homeName"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="名称:" prop="deviceName">
                <el-input type="text" v-model="inform.deviceName" placeholder="请输入设备名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="senseType==null">
              <el-form-item label="类型:" prop="senseType">
                <el-select v-model="inform.senseType" placeholder="请选择传感器类型">
                  <el-option v-for="(item,index) in dicObj.typeDataSen" :key="index" :label="item.dictName"
                             :value="item.dictValue"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="通讯端口:" prop="commPort">
                <el-select v-model="inform.commPort" placeholder="请选择通讯端口">
                  <el-option v-for="(item,index) in dicObj.portData" :key="index" :label="item.dictName"
                             :value="item.dictValue"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="序列号:" prop="deviceSerial">
                <el-input type="text" v-model="inform.deviceSerial" :disabled="true"
                          placeholder="请输入设备序列号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="输入路数:" prop="inputNumber">
                <el-input-number v-model="inform.inputNumber" :disabled="dioType=='edit'?true:false" :step="1"
                                 :min="1" :max="32" step-strictly></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备型号:" prop="deviceModel">
                <el-input type="text" v-model="inform.deviceModel" placeholder="请输入设备型号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="设备IP:" prop="deviceIp">
                <el-input type="text" v-model="inform.deviceIp" placeholder="请输入设备IP"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {apiUrl} from '@/assets/js/api'
import qs from 'qs'
import {delChildren} from '@/utils/utils'
import {uuid} from "../../../../utils/utils";

var vm;
export default {
  props: ['dicObj'],
  data() {
    var validateSerial = (rule, value, callback) => {
      if (this.inform.isRelay == 1) {
        if (!value) {
          return callback(new Error('请输入序列号'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    }
    return {
      isDio: false,//弹窗类型
      dioTit: '',//弹窗标题
      dioType: '',//弹窗类型
      dioWidth: '',//弹窗宽度
      senseType: '',//传感器类型
      isRelay: 0,//是否IO采集
      //form信息
      inform: {
        id: '',
        deviceIp: '',//设备IP
        homeId: '',//场所id
        deviceName: '',//名称
        deviceSerial: '',//设备序列号
        senseType: '',//传感器类型
        deviceModel: '',//设备型号
        isRelay: 1,//是否IO采集
        commPort: '',//通讯端口
        inputNumber: '',//输入路数
        outputNumber: '',//输出路数
      },

      rules: {
        homeId: [
          {required: true, message: '请选择场所', trigger: 'change'}
        ],
        commPort: [
          {required: true, message: '请选择通讯端口', trigger: 'blur'}
        ],
        deviceName: [
          {required: true, message: '请输入设备名称', trigger: 'blur'}
        ],
        deviceSerial: [
          // { required: true, message: '请输入设备序列号', trigger: 'blur' }
          {validator: validateSerial, trigger: 'blur', required: true},
        ],
        isRelay: [
          {required: true, message: '请选择是否IO采集', trigger: 'change'}
        ],
        senseType: [
          {required: true, message: '请选择传感器类型', trigger: 'change'}
        ],
        inputNumber: [
          {required: true, message: '请输入输入路数', trigger: 'blur'}
        ],
      },
    }
  },
  created() {
    vm = this

  },
  mounted() {

  },

  //方法
  methods: {
    //初始化
    init(type, item, obj) {
      // console.log(item);
      vm.isRelay = obj.isRelay;
      vm.senseType = obj.senseType;
      vm.dioType = type;
      vm.dioWidth = '740px';
      vm.isDio = true;
      vm.$nextTick(() => {
        if (type == 'add') {
          vm.dioTit = '添加串口服务器';
          vm.inform.deviceSerial = uuid(8);
          vm.inform.inputNumber = 4;
        } else if (type == 'edit') {
          vm.dioTit = '修改串口服务器'
          vm.inform = {
            id: item.id,
            deviceIp: item.deviceIp,//设备IP
            homeId: item.homeId,//场所id
            deviceName: item.deviceName,//名称
            deviceSerial: item.deviceSerial,//设备序列号
            senseType: item.senseType?.toString(),//传感器类型
            deviceModel: item.deviceModel,//设备型号
            isRelay: vm.isRelay,//是否IO采集
            commPort: item.commPort?.toString(),//通讯端口
            inputNumber: item.inputNumber//输入路数
          }
        }
      })
    },
    //校验form
    checkForm(formEl) {
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if (vm.dioType == 'add') {
            vm.addDeviceSense()
          } else {
            vm.updateDeviceSense()
          }
        } else {
          return false
        }
      })
    },
    //关闭弹窗
    closeDio(formEl) {
      // vm.$nextTick(() => {
      vm.$refs[formEl].resetFields()
      // })
      vm.isDio = false
    },

   //新增
    async addDeviceSense() {
      let dataObj = {
        'homeId': vm.inform.homeId,//场所id
        'deviceIp': vm.inform.deviceIp,//设备IP
        'deviceName': vm.inform.deviceName,//名称
        'deviceSerial': vm.inform.deviceSerial,//设备序列号
        'senseType': vm.senseType != null ? vm.senseType : vm.inform.senseType,//传感器类型
        'deviceModel': vm.inform.deviceModel,//设备型号
        'isRelay': vm.isRelay,//是否IO采集
        'commPort':vm.inform.commPort,//通讯端口
      }
      if (vm.inform.isRelay == 1) {
        dataObj.commPort = vm.inform.commPort
        dataObj.inputNumber = vm.inform.inputNumber
      }
      const res = await apiUrl.addDeviceSense(dataObj)
      if (res?.code == 200) {
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      } else {
        vm.$message.error(res.message)
      }
    }
    ,
//修改
    async updateDeviceSense() {
      let dataObj = {
        'id': vm.inform.id,
        'homeId': vm.inform.homeId,//场所id
        'deviceIp': vm.inform.deviceIp,//设备IP
        'deviceName': vm.inform.deviceName,//名称
        'deviceSerial': vm.inform.deviceSerial,//设备序列号
        'senseType': vm.inform.senseType,//传感器类型
        'deviceModel': vm.inform.deviceModel,//设备型号
        'isRelay': vm.inform.isRelay,//是否IO采集
      }
      if (vm.inform.isRelay == 1) {
        dataObj.commPort = vm.inform.commPort
        dataObj.inputNumber = vm.inform.inputNumber
      }
      const res = await apiUrl.updateDeviceSense(dataObj)
      if (res?.code == 200) {
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      } else {
        vm.$message.error(res.message)
      }
    }
    ,

  }
}
</script>
<style lang='scss'>
.deviceSensorAddEdit {
  .h500 {
    max-height: 500px;
    overflow: auto;
  }

  .bb1 {
    border-bottom: 1px solid transparent;
  }
}
</style>