<template>
  <div class="sensorSwitchAddEdit" v-if="isDio">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false"
               @close="closeDio('dioform')">
      <div class="tips fs12 error mb10" v-if="jsonMessage!=''"><em class="mr10">*</em>{{ jsonMessage }}</div>
      <div class="switchCont">
        <jsonView :data="config" theme="one-dark" deep="5"></jsonView>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {apiUrl} from '@/assets/js/api'
import jsonView from 'vue-json-views'

var vm;
export default {
  props: {
    sourceConn: {
      type: Number,
      default() {
        return -1
      }
    },
    targetConn: {
      type: Number,
      default() {
        return -1
      }
    },
  },
  components: {
    jsonView
  },
  data() {
    return {
      isDio: false,//弹窗类型
      dioTit: '',//弹窗标题
      dioType: '',//弹窗类型
      dioWidth: '',//弹窗宽度

      config: {},
      jsonMessage: '',//数据是否加载完成
    }
  },
  created() {
    vm = this

  },
  mounted() {

  },

  //方法
  methods: {
    //初始化
    async init(type, id) {
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '740px'
      vm.isDio = true
      await vm.getConfig(id)
      vm.dioTit = '串口服务器网关边缘计算配置信息'
    },
    //获取IO采集开关列表
    async getConfig(id) {
      const res = await apiUrl.getFourSerialConfig(id)
      if (res?.code == 200) {
        vm.config = res.data;
        const dataCode = res.data.code;
        vm.jsonMessage = '';
        // console.log(res.data,dataCode);
        if (dataCode === 1) {
          vm.jsonMessage = "串口服务器未配置MQTT信息";
        } else if (dataCode === 2) {
          vm.jsonMessage = "串口服务器未配置边缘传感器";
        }
      } else {
        this.$message.error(res.message)
      }
    },
    //关闭弹窗
    closeDio(formEl) {
      // vm.$nextTick(() => {
      // vm.$refs[formEl].resetFields()
      // })

      vm.config = {};
      vm.jsonMessage = '';


      vm.isDio = false
    },

  }
}
</script>
<style lang='scss'>
.sensorSwitchAddEdit {
  .switchCont {
    position: relative;
    height: 500px;
    overflow: auto;
  }

  .contDio {
    display: flex;
    justify-content: space-between;
    user-select: none;
    position: absolute;
    width: 100%;
    // height: 1000px;
    left: 0;
    top: 0;
    // overflow: auto;
    .leftDiv, .rightDiv {
      width: 45%;
      flex-shrink: 0;

      .list {
        // height:200px;
        // overflow-y:auto;
        position: relative;

        .item {
          // border: 1px solid #ddd;
          border-radius: 4px;
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          padding: 2px 5px;
          background: #112547;
          cursor: pointer;

          .no {
            margin-right: 10px;
          }
        }
      }

      .title {
        text-align: center;
        font-size: 16px;
      }
    }
  }
}
</style>