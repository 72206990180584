<template>
  <div class="sensorSwitch">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio">
      <div class="contDio">
        <el-button type="primary" size="small" @click="openDio('fourSerialServerConfig')"><i
            class="el-icon-plus"></i>获取配置
        </el-button>
        <div class="content">
          <div class="leftDiv">
            <div class="title">串口配置</div>
            <div class="list" :model="inform">
              <div class="item" v-for="(item,index) in inform.inData" :key="index">
                <div class="no">串口{{ item.switchNumber }}</div>
                <el-select v-model="item.connectDeviceIds" placeholder="请选择传感设备" multiple size="small" clearable
                           @change="selChange">
                  <el-option v-for="(item,index) in deviceList" :key="index" :label="item.deviceName" :value="item.id"
                             :disabled="item.disabled"></el-option>
                </el-select>
                <el-button type="primary" size="small" @click="openDio('configEditRef',item)">地址配置</el-button>
              </div>
            </div>
          </div>
          <div class="rightDiv">
            <div class="title">采集配置</div>
            <div class="list">
              <el-form :rules="rules" :model="inform.mqtt" ref="dioform" label-width="100px" size="small">
                <el-row>
                  <el-col>
                    <el-form-item label="mqtt地址:" prop="hostUrl">
                      <el-input type="text" v-model="inform.mqtt.hostUrl"
                                placeholder="请输入地址，格式tcp://ip:port"></el-input>
                    </el-form-item>
                    <el-form-item label="mqtt账户:" prop="username">
                      <el-input type="text" v-model="inform.mqtt.username" placeholder="请输入mqtt账户"></el-input>
                    </el-form-item>
                    <el-form-item label="mqtt密码:" prop="password">
                      <el-input type="text" v-model="inform.mqtt.password" placeholder="请输入mqtt密码"></el-input>
                    </el-form-item>
                    <el-form-item label="主题发布：" prop="topic">
                      <el-input type="text" v-model="inform.mqtt.topic" readonly
                                placeholder="主题：/sensor/${deviceSerial}/upload"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" size="small" @click="checkForm('dioform')">提交</el-button>
          </span>

    </el-dialog>
    <config-view ref="serialServerConfigRef"></config-view>
    <config-edit ref="configEditRef" @update="updateItem"></config-edit>
  </div>
</template>
<script>
import {apiUrl} from '@/assets/js/api'
import qs from 'qs'
import configView from "./config-view";
import configEdit from "./config-edit";

var vm;
export default {
  props: ['dicObj'],
  data() {
    const regex = /^tcp:\/\/(?:[0-9]{1,3}\.){3}[0-9]{1,3}:\d+$/;
    const checkMqttIPCode = (rule, value, callback) => {
      if ((regex.test(value) == false)
          && value != '') {
        callback(new Error("请输入正确的mqtt地址,格式tcp://ip:port"));
      } else {
        callback();
      }
    }
    return {
      isDio: false,//弹窗类型
      dioTit: '',//弹窗标题
      dioType: '',//弹窗类型
      dioWidth: '',//弹窗宽度
      //form信息
      inform: {
        id: '',
        relayDeviceId: '',//IO采集ID
        inData: [],//输入数据
        outData: [],//输出数据
        senseType: '',//传感器类型
        mqtt: {
          topic: '/sensor/${deviceSerial}/upload',
          hostUrl: '',
          username: 'admin',
          password: 'admin',
        }
      },

      deviceList: [],//设备列表
      rules: {
        hostUrl: [
          {required: true, message: '请输入正确的mqtt地址,格式tcp://ip:port', trigger: 'blur',validator:checkMqttIPCode}
        ],
        username: [
          {required: true, message: '请输入mqtt账户', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入账户密码', trigger: 'blur'}
        ],
        topic: [
          {required: true, message: '', trigger: 'blur'}
        ],

        isRelay: [
          {required: true, message: '请选择是否IO采集', trigger: 'change'}
        ]
      },
    }
  },
  components: {
    configView, configEdit
  },
  created() {
    vm = this
  },

  //方法
  methods: {

    //初始化
    async init(type, item) {
      // console.log(item);
      vm.dioType = type
      if (type == 'config') {
        vm.dioTit = '串口服务器配置';
        vm.inform.id = item.id;
        vm.inform.deviceType = item.deviceType;
        vm.inform.relayDeviceId = item.id;
        vm.inform.senseType = item.senseType;
        await vm.deviceSenseUnbound(item.id);
        vm.getDeviceRelay(item.id);
        vm.inform.mqtt = {
          id: 0,
          topic: '/sensor/' + item.deviceSerial + '/upload',
          hostUrl: '',
          username: 'admin',
          password: 'public',
          deviceId: item.deviceId,
          deviceType: item.deviceType,
          deviceSubType: item.senseType
        }
        await vm.getMqttConfig(vm.inform.mqtt)

      }
      vm.dioWidth = '840px'
      vm.isDio = true
    },
    //下拉选择
    selChange(val) {
      let idArr = []
      for (var i in this.inform.inData) {
        if (this.inform.inData[i].connectDeviceIds.length > 0) {
          idArr.push(...this.inform.inData[i].connectDeviceIds);
        }
      }
      this.deviceList = this.deviceList.map(item => ({
        ...item, // 使用扩展运算符来复制当前对象  
        disabled: idArr.includes(item.id) // 如果id在idArr中存在，则disable为true，否则为false  
      }));
    },
    //获取未绑定的设备
    async deviceSenseUnbound(id) {
      let dataObj = {
        'id': id
      }
      const res = await apiUrl.deviceSenseUnbound(qs.stringify(dataObj))
      if (res?.code == 200) {
        if (res.data?.length > 0) {
          for (var i in res.data) {
            res.data[i].disabled = false;
          }
        }
        this.deviceList = res.data;
      } else {
        this.$message.error(res.message)
      }
    },
    //获取未绑定的设备
    async getMqttConfig(mqttConfig) {
      let dataObj = {
        'deviceId': mqttConfig.deviceId,
        'deviceType': mqttConfig.deviceType,
        'deviceSubType': mqttConfig.deviceSubType,
      }
      const res = await apiUrl.getMqttConfig(qs.stringify(dataObj))
      if (res?.code == 200) {
        if (res.data?.length > 0) {
          this.inform.mqtt = res.data[0]
          this.inform.mqtt.edit = 1;
        } else {
          this.inform.mqtt.edit = 0;
        }
      } else {
        this.$message.error(res.message)
      }
    },
    //获取IO采集开关列表
    async getDeviceRelay(id) {
      let dataObj = {
        'id': id,
      }
      const res = await apiUrl.getDeviceRelay(qs.stringify(dataObj))
      if (res?.code == 200) {
        let inDataArr = res.data.inputArray
        //下面两个for是把已绑定的设备放到下拉设备列表里面去
        for (var i in inDataArr) {
          inDataArr[i].addressConfigArr = inDataArr[i].addressConfigArr ?? [];
          for (var j in inDataArr[i].addressConfigArr) {
            let obj = {
              'id': inDataArr[i].addressConfigArr[j].id,
              'deviceName': inDataArr[i].addressConfigArr[j].name,
              'disabled': true,
            }
            this.deviceList.push(obj)
          }

        }
        vm.inform.inData = inDataArr;
      } else {
        this.$message.error(res.message)
      }
    },
    //打开弹窗
    openDio(type, item) {
      if (type == 'fourSerialServerConfig') {
        vm.$refs.serialServerConfigRef.init(type, this.inform.relayDeviceId);//关联
      } else if (type == 'configEditRef') {
        let arr = item.connectDeviceIds;
        let addressConfigArr = item.addressConfigArr ?? [];
        //从this.deviceList 中 筛选出 id 包含在 arr 中的对象
        let selectedObjArr = this.deviceList.filter(obj => arr.includes(obj.id));
        for (var i in selectedObjArr) {
          selectedObjArr[i].address = 1;
          const id = selectedObjArr[i].id;
          const index = addressConfigArr.findIndex(obj => obj.id == id);
          if (index !== -1) {
            selectedObjArr[i].address = addressConfigArr[index].address ?? 1;
          }
        }
        vm.$refs.configEditRef.init(item, selectedObjArr);//关联
      }
    },
    updateItem(item) {
      let findIndex = vm.inform.inData.findIndex(obj => obj.id == item.id);
      if (findIndex !== -1) {
        vm.inform.inData[findIndex].addressConfigArr = item.addressConfigArr;
      }
      console.log(vm.inform.inData);
    },
    //提交
    checkForm(formEl) {
      this.updateDeviceRelay()
    },
    //更新
    async updateDeviceRelay() {
      let arr = []
      let inData = this.inform.inData;
      for (var i = 0; i < inData.length; i++) {
        let selectedArr = inData[i].connectDeviceIds;
        let switchNumber = inData[i].switchNumber;
        let switchType = inData[i].switchType;
        let relayId = inData[i].id;
        let senseId = this.inform.id;
        for (var j = 0; j < selectedArr.length; j++) {
          console.log(inData[i].addressConfigArr);
          let item = (inData[i].addressConfigArr ?? []).find(obj => obj.id == selectedArr[j]);
          console.log(item);
          let obj = {
            'senseId': senseId,
            'relayId': relayId,
            'switchNumber': switchNumber,
            'switchType': switchType,
            'relayDeviceId': this.inform.relayDeviceId,
            'connectDeviceId': selectedArr[j],
            'address': item ? (item.address ?? 1) : 1
          }
          arr.push(obj);
        }
      }
      let mqttConfig = {
        ...this.inform.mqtt
      }
      const promise = await apiUrl.updateDeviceMqtt(mqttConfig);
      if (promise?.code == 200) {
        this.$message.success('更新mqtt配置成功！')
      } else {
        this.$message.error(promise.message)
      }
      const data = {
        'id': vm.inform.id,
        'deviceType': vm.inform.deviceType,
        'senseType': vm.inform.senseType,
        'deviceRelayList': arr
      }
      const res = await apiUrl.updateSerialDeviceRelay(data);
      if (res?.code == 200) {
        this.$message.success('更新成功！')
        this.closeDio('dioform')
      } else {
        this.$message.error(res.message)
      }
    },
    //关闭弹窗
    closeDio(formEl) {
      vm.inform = {
        id: '',
        relayDeviceId: '',//IO采集ID
        inData: [],//输入数据
        outData: [],//输出数据
        senseType: '',
        mqtt: {
          id: '',
          topic: '',
          hostUrl: '',
          username: '',
          password: '',
          deviceId: '',
          deviceType: '',
          deviceSubType: ''
        }
      }
      vm.deviceList = []//设备列表
      vm.isDio = false
    },

  }
}
</script>
<style lang='scss'>
.sensorSwitch {
  .contDio {
    color: #fff;

    .content {
      display: flex;
      justify-content: space-between;
      position: relative;

      .leftDiv, .rightDiv {
        width: 45%;
        flex-shrink: 0;

        .list {
          max-height: 500px;
          overflow: auto;

          .item {
            // border: 1px solid #ddd;
            border-radius: 4px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            padding: 5px;
            background: #112547;
            justify-content: space-around;

            .el-input__inner {
              background-color: #031e44;
              color: #00c6ff;
              border-color: #1d4975;

              &:hover {
                border-color: #1d4975;
              }

              &::placeholder {
                color: #00c6ff;
              }
            }

            .no {
              margin-right: 10px;
            }

            .el-select {
              z-index: 1;
            }
          }
        }

        .title {
          text-align: center;
          font-size: 16px;
          padding: 10px 0;
        }
      }
    }
  }
}
</style>