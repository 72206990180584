import { render, staticRenderFns } from "./config-edit.vue?vue&type=template&id=8044e548"
import script from "./config-edit.vue?vue&type=script&lang=js"
export * from "./config-edit.vue?vue&type=script&lang=js"
import style0 from "./config-edit.vue?vue&type=style&index=0&id=8044e548&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports