<template>
  <div class='safetyDevice mainContainer safetyAlarmLogs'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="searchCont mb15">
      <div class="title_name">
        <div>检索条件</div>
      </div>
      <el-form ref="searchForm" label-width="100px" inline @submit.native.prevent size="small" v-model="keyWord">
        <el-form-item label="设备名称:" prop="deviceName">
          <el-input type="text" class="w180" v-model="keyWord.deviceName" placeholder="请输入设备名称" clearable
                    @keyup.enter.native="getList(1)"/>
        </el-form-item>
        <el-form-item label="设备序列号:" prop="deviceSerial">
          <el-input type="text" class="w180" v-model="keyWord.deviceSerial" placeholder="请输入设备序列号" clearable
                    @keyup.enter.native="getList(1)"/>
        </el-form-item>
        <el-form-item label="状态:" prop="status">
          <el-select v-model="keyWord.status" placeholder="选择状态" class="w100" @change="getList(1)" clearable>
            <el-option v-for="(item,index) in dicObj.statusData" :key="index" :label="item.dictName"
                       :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="">
          <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="">
      <div class="title_name">
        <div>设备总数：<span class="fs16 col00ffe">{{ total }}</span></div>
        <div>
          <el-button type="text" @click="openDio('add')" v-if="isAuth('safe:safety:device:sensor:add')"><i
              class="el-icon-circle-plus-outline"></i>添加
          </el-button>
          <el-button type="text" class="error" @click="batchDel()" v-if="isAuth('safe:safety:device:sensor:del')"><i
              class="el-icon-delete"></i>批量删除
          </el-button>
        </div>
      </div>
      <div :style="{height:tableH+'px',overflow:'auto'}">
        <el-checkbox-group v-model="tableActive">
          <div class="pageList">
            <div class="item" v-for="(item,index) in tableData" :key="index">
              <el-checkbox :label="item.id"></el-checkbox>
              <div class="liStatus tr">
                 <span title="在线状态" v-if="item.status==0"
                       :class="['status',{'success':false,'info':true}]">离线</span>
                <span title="在线状态" v-if="item.status==1"
                      :class="['status',{'success':true,'info':false}]">在线</span>
                <span title="设备告警" v-if="item.workState==1" class="status warning">
                    故障
                  </span>
              </div>
              <div class="liCont">
                <el-image fit="contain" :src="getIcon(item.senseType,item.isRelay)"></el-image>
                <div class="liRight">
                  <!-- <p :title="item.deviceSerial">{{item.deviceSerial}}</p> -->
                  <p class="name" :title="item.deviceName">{{ item.deviceName }}</p>
                  <p>{{ item.homeName }}</p>
                  <!-- <p>IO采集:<span class="success" v-if="item.isRelay=='1'">是</span><span class="info" v-else>否</span></p> -->
                  <p>设备类型:{{ item.senseType | statesFilter('1') }}</p>
                </div>
              </div>
              <div class="liTips" v-if="item.isRelay==1">
                <span><em>串口数:</em>{{ item.inputNumber }}个</span>
                <span><em>传感器:</em>{{ item.senseNumMap }}个</span>
              </div>
              <div class="liTips" v-if="item.senseType==0&&item.targetValueList&&item.targetValueList.length>0">
                <span v-for="(item2,index) in item.targetValueList"
                      v-if="item2.name=='温度'||item2.name=='湿度'">
                  <template v-if="item2.name=='温度'"><em>T: </em>{{ item2.value }}{{ item2.unit }}</template>
                  <template v-if="item2.name=='湿度'"><em>RH: </em>{{ item2.value }}{{ item2.unit }}</template>
                </span>
              </div>
              <div class="liBtn">
                <div class="btn el-icon-edit" v-if="isAuth('safe:safety:device:sensor:edit')" title="编辑"
                     @click="openDio('edit',item)"></div>
                <div class="btn el-icon-setting" v-if="isAuth('safe:safety:device:sensor:config')&&item.isRelay==1"
                     title="配置" @click="openDio('config',item)"></div>
                <div class="btn el-icon-s-order" v-if="isAuth('safe:safety:device:sensor:detail')&&item.senseType==0"
                     title="详情"
                     @click="openDio('detail',item)"></div>
                <div class="btn el-icon-warning"
                     v-if="isAuth('safe:safety:device:sensor:rule')&&(item.senseType==0||item.senseType==2)"
                     title="告警规则"
                     @click="openDio('alarmRule',item)"></div>
                <div class="btn el-icon-delete" v-if="isAuth('safe:safety:device:sensor:del')" title="删除"
                     @click="openDio('del',item)"></div>
              </div>
            </div>
          </div>
        </el-checkbox-group>
      </div>
      <div class="pageDiv">
        <el-pagination
            :current-page="curr"
            :page-size="limits"
            background
            layout="total,sizes, prev, pager, next, jumper"
            :page-sizes="[12, 20, 50, 100,200]"
            :total="total"
            @size-change="sizeChange"
            @current-change="currChange"
        />
      </div>
    </div>

    <!--创建、修改-->
    <sensor-add-edit ref="editDio" :dicObj="dicObj" @addEditSucc="getList()"></sensor-add-edit>
    <!--开关配置-->
    <sensor-switch ref="configDio" :dicObj="dicObj"></sensor-switch>
    <!--告警规则-->
    <alarm-rule ref="alarmRuleDio" :dicObj="dicObj"></alarm-rule>
    <sensor-detail ref="detailDio" :dicObj="dicObj"></sensor-detail>

  </div>
</template>
<script>
import sensorAddEdit from './sensor-add-edit'
import breadCrumb from '@/components/bread-crumb.vue'
import sensorSwitch from './sensor-switch'
import {apiUrl} from '@/assets/js/api';
import qs from 'qs';
import alarmRule from "@/views/safety/device/electric/alarm-rule";
import SensorDetail from "@/views/safety/device/sensor/sensor-detail";

let d_icon1 = require('@/assets/images/yan_gan.png');
let d_icon2 = require('@/assets/images/ipc.png');
let d_icon3 = require('@/assets/images/wen_shi_du.png');
let d_icon4 = require('@/assets/images/shui_jin.png');
let d_icon5 = require('@/assets/images/IO.png');
let d_icon6 = require('@/assets/images/USR-N540.png');
var vm;
export default {
  props: [],
  name: 'safety-device-fourserial',
  data() {
    return {
      //分页
      curr: 1,//当前页
      limits: 12,//每页容量
      total: 0,//数据总数

      tableH: '',//表格高度
      tableActive: [],//表格选中
      tableData: [],
      keyWord: {},
      dicObj: {},
      isAdmin: false,//是否是超级管理员
    }
  },
  components: {
    SensorDetail,
    sensorAddEdit,
    sensorSwitch, breadCrumb,
    alarmRule,
  },
  watch: {
    '$store.state.homeId': function (newVal) {
      if (newVal !== '0') {
        vm.$nextTick(() => {
          vm.getList()
        })
      }
    },
    '$store.state.csType': function (newVal) {
      if (newVal !== '0') {
        vm.$nextTick(() => {
          vm.getHome()
        })
      }
    },
  },
  async created() {
    vm = this
    vm.initHeight()
    let userInfo = this.vtp.get('userInfo')
    if (userInfo) {
      this.isAdmin = userInfo.isAdmin
    }
    await vm.getCurrDics();
    await vm.getList();
    await vm.getHome();
  },
  mounted() {
    window.onresize = () => {
      vm.initHeight()
    }
  },
  filters: {
    //状态过滤器
    statesFilter(val, type) {
      //传感器类型
      if (type == '1') {
        let result = vm.dicObj.typeDataSen.filter(item => item.dictValue == val)
        return result[0]?.dictName || '-'
      }
    },
  },
  //方法
  methods: {
    //获取场所
    async getHome() {
      let dataObj = {
        'areaId': this.$store.state.csType
      }
      const res = await apiUrl.getHome(qs.stringify(dataObj))
      if (res?.code == 200) {
        vm.dicObj.homeList = res.data
      }
    },
    //获取数据字典
    async getCurrDics() {
      let dataObj = ['DEVICE_STATUS', 'ALARM_CATEGORY', 'DOMAIN_DEVICE_BRAND', 'DEVICE_TAG',
        'PLATFORM_TYPE', 'COMM_PORT', 'SENSE_TYPE', 'SWITCH_NUMBER', 'ALARM_TYPE', 'ALARM_RULE_TYPE', 'DEVICE_TYPE',
        'AIR_CONDITION_MODEL']
      const res = await apiUrl.getDicts(dataObj);
      if (res?.code == 200) {
        vm.dicObj.statusData = res.data.DEVICE_STATUS || [];//设备状态
        vm.dicObj.alarmData = res.data.ALARM_CATEGORY || [];//告警类别
        vm.dicObj.brandData = res.data.DOMAIN_DEVICE_BRAND || [];//设备品牌
        vm.dicObj.tagData = res.data.DEVICE_TAG || [];//设备标签
        vm.dicObj.platformData = res.data.PLATFORM_TYPE || [];//平台类型
        vm.dicObj.portData = res.data.COMM_PORT || [];//IO采集端口
        vm.dicObj.typeDataSen = res.data.SENSE_TYPE || [];//传感器类型
        vm.dicObj.numberData = res.data.SWITCH_NUMBER || [];//IO采集开关数量
        vm.dicObj.alarmTypeData = res.data.ALARM_TYPE || [];//告警类型
        vm.dicObj.ruleTypeData = res.data.ALARM_RULE_TYPE || [];//告警规则类型
        vm.dicObj.airTypeData = res.data.DEVICE_TYPE || [];//空调设备类型
        vm.dicObj.airModelData = res.data.AIR_CONDITION_MODEL || [];//空调设备型号
        vm.dicObj.typeDataElt = res.data.DEVICE_TYPE || [];//电表设备类型
      } else {
        vm.$message.error(res.message)
      }
    },
    getIcon(type, isRelay) {
      if (isRelay == '1') {
        if (type == '5') {
          return d_icon6;
        } else {
          return d_icon5
        }
      } else {
        if (type == '0') {//温湿度
          return d_icon3
        } else if (type == '1') {//烟感
          return d_icon1
        } else if (type == '2') {//水浸
          return d_icon4
        } else if (type == '3') {//火焰
          return d_icon2
        }
      }
    },
    //打开弹窗
    openDio(type, item) {
      if (type == 'del') {
        vm.$confirm('确定删除该选项', '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            }
        )
            .then(() => {
              let arr = []
              if (item) {
                arr.push(item.id)
              }
              vm.deleteDeviceSense(arr)
            })
            .catch(() => {
            })
      } else {
        if (type == 'add' || type == 'edit') {
          vm.$refs.editDio.init(type, item, {isRelay: 1, senseType: 5});//新增或修改
        } else if (type == 'config') {
          vm.$refs.configDio.init(type, item);//开关配置
        } else if (type == 'alarmRule') {
          vm.$refs.alarmRuleDio.init(type, item);//告警规则
        } else if (type == 'detail') {
          vm.$refs.detailDio.init(type, item);//详情
        }
      }
    },
    //批量删除
    batchDel() {
      if (vm.tableActive.length == 0) {
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      this.$confirm('是否确定要删除所选数据，删除后不可恢复？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        vm.deleteDeviceSense(vm.tableActive);//删除
      }).catch(() => {
      });
    },
    //删除
    async deleteDeviceSense(ids) {
      let dataObj = {
        'data': ids
      }
      const res = await apiUrl.deleteDeviceSense(dataObj)
      if (res?.code == 200) {
        vm.$message.success('删除成功')
        let totalPage = Math.ceil((this.total - ids.length) / this.limits);
        let current = this.curr > totalPage ? totalPage : this.curr;
        this.curr = current < 1 ? 1 : current;
        vm.getList()
      } else {
        vm.$message.error(res.message)
      }
    },
    //分页选择
    sizeChange(val) {
      vm.limits = val
      vm.getList(vm.curr, val)
    },
    currChange(val) {
      vm.curr = val
      vm.getList(val)
    },
    //获取列表
    async getList(_curr, _limits) {
      vm.tableActive = []
      let dataObj = {
        'areaId': vm.$store.state.csType,
        'homeId': vm.$store.state.homeId,
        'senseType': 5,//四串口服务
        'isRelay': 1,//IO采集器
        'pageNo': _curr ? _curr : vm.curr,
        'pageSize': _limits ? _limits : vm.limits,
        ...vm.keyWord
      }
      const res = await apiUrl.deviceSensePage(dataObj)
      if (res?.code == 200) {
        vm.tableData = res.data.list
        vm.total = parseInt(res.data.totalSize)
        vm.curr = _curr ? _curr : vm.curr
      } else {
        vm.$message.error(res.message)
      }
    },
    //设置高度
    initHeight() {
      var minWin = window.innerHeight;
      vm.tableH = minWin - 350
    }
  }
}
</script>
<style lang='scss'>
.safetyDevice {
  .el-tabs__content {
    padding: 10px 10px 0;
  }

  .el-checkbox-group {
    font-size: 14px;
  }

  .pageList {
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    .item {
      width: calc((100% - 45px) / 4);
      height: 149px;
      padding: 15px 10px;
      box-sizing: border-box;
      margin: 10px 7.5px;
      flex-shrink: 0;
      background: url('../../../../assets/images/ui/item_bg.png') no-repeat;
      background-size: 100% 100%;
      position: relative;

      &:nth-child(4n) {
        margin-right: 0px;
      }

      &:nth-child(4n-3) {
        margin-left: 0px;
      }

      .el-checkbox {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        z-index: 1;

        .el-checkbox__input {
          display: none;
        }

        &.is-checked {
          .el-checkbox__input {
            display: block;
          }
        }

        .el-checkbox__label {
          display: none;
        }

        .el-checkbox__inner {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          left: 10px;
          top: 10px;

          &::after {
            left: 7px;
            width: 6px;
            height: 12px;
          }
        }
      }

      .liStatus {
        height: 19px;

        .status {
          border-right: 1px solid #1d4975;
          padding: 0 5px;
          position: relative;
          z-index: 1;

          &:last-child {
            border-right: none;
          }
        }
      }

      .liBtn {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: rgba(33, 97, 155, .75);
        padding: 5px;
        box-sizing: border-box;
        display: none;
        align-items: center;
        justify-content: space-around;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 3;

        .btn {
          padding: 5px;
          cursor: pointer;
          font-size: 18px;
          color: #00c6ff;

          &.el-icon-delete {
            color: #F56C6C;
          }

          &:hover {
            opacity: .8;
          }
        }
      }

      &:hover .liBtn {
        display: flex;
      }

      .liTips {
        color: #00c6ff;
        margin-top: 5px;
        display: flex;
        justify-content: space-around;
      }

      .liCont {
        display: flex;

        .el-image {
          width: 35%;
          height: 80px;
          flex-shrink: 0;
          margin-right: 5px;
          position: relative;
          z-index: 0;
        }

        .liRight {
          width: calc(100% - 35% - 5px);
          flex-shrink: 0;

          i {
            font-size: 30px;
            color: #00c6ff;
            cursor: pointer;
          }

          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #fff;
            line-height: 24px;

            &.name {
              font-size: 14px;
              color: #00c6ff;
            }
          }
        }
      }

      &:hover {
        box-shadow: 0 0 3px 5px rgba(0, 0, 0, .15);
      }
    }
  }
}
</style>
